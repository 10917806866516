.barkdown {
  h1 {
    font-size: 24px;
    border-top: solid 1px #ccc;
    margin: 0;
    margin-top: 10px;
  }
  h2 {
    font-size: 20px;
    margin: 0;
    margin-top: 10px;
  }
  h3 {
    font-size: 16px;
    margin: 0;
    margin-top: 10px;
  }
  img {
    max-width: 200px;
    display: block;
  }
  b {
    &:before {
      content: ' ';
    }
    &:after {
      content: ' ';
    }
  }
  em {
    &:before {
      content: ' ';
    }
    &:after {
      content: ' ';
    }
  }
  u {
    &:before {
      content: ' ';
    }
    &:after {
      content: ' ';
    }
  }
  // https://css-tricks.com/snippets/css/simple-and-nice-blockquote-styling/
  blockquote {
    background: #f9f9f9;
    border-left: 10px solid #ccc;
    margin: 1.5em 10px;
    padding: 0.5em 10px;
    quotes: '\201C''\201D''\2018''\2019';
    footer {
      font-weight: bold;
      color: #666;
      margin-top: 0.25em;
      margin-left: 1.5em;
    }
    > div:first-child {
      color: #ccc;
      font-size: 4em;
      line-height: 0.1em;
      position: relative;
      top: 0.085em;
      left: -0.1em;
    }
  }
}

.test {
  font-size: 100;
}
